import type { NextRequest } from 'next/server'

// Make sure this matches next.config.mjs
export const CookieName = 'CIQ_Redesign'
export const DefaultValue = process.env.NEXT_PUBLIC_REDESIGN_DEFAULT === 'true'

export function isOptedIn(cookieJar: Pick<NextRequest['cookies'], 'get'>): boolean {
  const cookie = cookieJar.get(CookieName)
  return cookie ? cookie.value === 'yes' : DefaultValue
}
