import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import Cookies from 'js-cookie'

import { CookieName } from 'app/(logged-in)/opt-in-out/RedesignSwitcher'

/**
 * Type predicate to narrow an unknown error to `FetchBaseQueryError`
 */
export function isFetchBaseQueryError(error: unknown): error is FetchBaseQueryError {
  return typeof error === 'object' && error != null && 'status' in error
}

/**
 * Type predicate to narrow an unknown error to an object with a string 'message' property
 */
export function isErrorWithMessage(error: unknown): error is { message: string } {
  return typeof error === 'object' && error != null && 'message' in error && typeof error.message === 'string'
}

export const setJWTCookie = (newAccessToken: string) => {
  const sameSite = process.env.NEXT_PUBLIC_DISABLE_SECURE_COOKIES === 'true' ? 'Lax' : 'None'
  const cookieSecurity: Cookies.CookieAttributes = {
    sameSite,
    secure: process.env.NEXT_PUBLIC_DISABLE_SECURE_COOKIES !== 'true',
  }

  Cookies.set('JWT', newAccessToken, cookieSecurity)

  // Now that login is complete we no longer need the PKCE Verifier
  window.sessionStorage.removeItem('PKCE_Verifier')
  // Or any theme settings
  window.sessionStorage.removeItem('theme')
}

export const getJWTCookie = () => Cookies.get('JWT')

export const removeJWTCookie = () => Cookies.remove('JWT')

export const isValidIndex = (index: number | undefined): index is number => index !== undefined && index >= 0

export function isRedesignEarlyAdopter(accountId: string): boolean {
  if (process.env.NEXT_PUBLIC_REDESIGN_COOKIE_OPT_IN !== 'true') {
    return false
  }

  if (process.env.NEXT_PUBLIC_REDESIGN_FORCE_FOR_ALL === 'true') {
    return true
  }

  try {
    const earlyAdoptersStr = process.env.NEXT_PUBLIC_REDESIGN_EARLY_ADOPTERS
    if (earlyAdoptersStr) {
      const earlyAdopterAccountIds = JSON.parse(earlyAdoptersStr) as string[]
      return earlyAdopterAccountIds.includes(accountId)
    }
  } catch {
    // Ignore, probably .env things
  }

  return false
}

/**
 * Whether the cookie exists, not whether it's yes/no - used to decide whether to set by default.
 */
export function hasRedesignOptInCookie() {
  return typeof Cookies.get(CookieName) === 'string'
}

export function setRedesignOptInCookie() {
  if (process.env.NEXT_PUBLIC_REDESIGN_COOKIE_OPT_IN !== 'true') {
    return
  }

  const sameSite = window.DISABLE_SECURE_COOKIES ? 'Lax' : 'None'
  const cookieSecurity: Cookies.CookieAttributes = { sameSite, secure: !window.DISABLE_SECURE_COOKIES }
  Cookies.set(CookieName, 'yes', cookieSecurity)
}

export function removeRedesignOptInCookie() {
  Cookies.remove(CookieName)
}
