'use client'

import React from 'react'

import AppBar from '@mui/material/AppBar'
import Box, { boxClasses } from '@mui/material/Box'
import Button, { buttonClasses, ButtonProps } from '@mui/material/Button'
import Link, { LinkProps } from '@mui/material/Link'
import Stack, { stackClasses } from '@mui/material/Stack'
import { alpha, styled } from '@mui/material/styles'
import Toolbar from '@mui/material/Toolbar'
import { typographyClasses } from '@mui/material/Typography'
import { usePathname } from 'next/navigation'

import CIQIconButton from 'components/atoms/CIQIconButton/CIQIconButton'
import { CIQIconButtonProps } from 'components/atoms/CIQIconButton/CIQIconButton.types'

export const TopBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: 'transparent',
  color: theme.palette.primary.main,

  transition: `${theme.transitions.create(['color'], {
    duration: theme.transitions.duration.enteringScreen,
  })}`,

  [`> .${stackClasses.root} > .${boxClasses.root}`]: {
    backgroundColor: theme.palette.background.default,
    borderRadius: '100px',
    background: alpha('#ebe8fc', 0.9),
    height: '46px',

    transition: `${theme.transitions.create(['background-color', 'border-color'], {
      duration: theme.transitions.duration.standard,
    })}`,

    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      inset: 0,
      borderRadius: '100px',
      padding: '1px',
      background: `linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%)`,
      mask: `linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)`,
      maskComposite: 'exclude',
      opacity: 0.5,
    },
  },
}))

export const Main = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
}))

export const Content = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  flexShrink: 1,
  maxWidth: '980px',
  borderRadius: '30px',
  margin: theme.spacing(2, 4),
  boxShadow: '0px 0px 40px 0px rgba(103, 80, 164, 0.10)',

  position: 'relative',
  '&:before': {
    content: '""',
    position: 'absolute',
    inset: -15,
    borderRadius: '45px',
    padding: '15px',
    background: `linear-gradient(120.12deg, rgba(238, 240, 250, 0.09) 31.64%, #DEECF5 45.72%, rgba(255, 255, 255, 0.51) 59.48%);`,
    mask: `linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)`,
    maskComposite: 'exclude',
    backdropFilter: 'blur(1px)',
    boxShadow: `0px 0px 40px 0px rgba(103, 80, 164, 0.1)`,
    zIndex: -1,
  },
}))

export const ContentBoxGlow = styled(Box, {
  shouldForwardProp: prop => prop !== 'anchor',
})<{ anchor: 'top' | 'bottom' }>(({ theme, anchor }) => ({
  position: 'absolute',
  content: '""',
  display: 'block',
  left: '50%',

  ...(anchor === 'top' && {
    top: -30,
  }),
  ...(anchor === 'bottom' && {
    bottom: -30,
  }),

  transform: 'translateX(-50%)',
  width: '91.5%',
  height: '175px',
  borderRadius: '50%',
  background: `rgba(0, 175, 175, 0.3)`,
  filter: 'blur(32px)',
  zIndex: -1,
}))

export const BackgroundContainer = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  position: 'fixed',
  inset: 0,
  overflow: 'hidden',
  zIndex: -1,
  backgroundColor: theme.palette.lightSurface.main,
  backgroundImage: `linear-gradient(282.89deg, rgba(103, 80, 164, 0.042) 31.51%, rgba(208, 188, 255, 0.35) 54.82%, rgba(103, 80, 164, 0.35) 65.39%, rgba(220, 39, 115, 0.35) 87.2%, rgba(217, 217, 217, 0) 99.63%)`,
  backgroundAttachment: 'fixed',
}))

export const LeftBackgroundContainer = styled(Box)(({ theme }) => ({
  content: '""',
  position: 'absolute',
  top: -75,
  left: -100,
  background: `rgba(130, 107, 192, 0.18)`,
  boxShadow: `0px 6.4px 32px 0px rgba(0, 0, 0, 0.05)`,
  transform: 'rotate(-18.27deg)',
  borderRadius: '48px',
  width: '404.08px',
  height: '268.85px',
  zIndex: -1,
}))

export const RightBackgroundContainer = styled(Box)(({ theme }) => ({
  content: '""',
  position: 'absolute',
  right: -100,
  bottom: -50,
  background: `linear-gradient(201.69deg, #CDD8E2 31.02%, rgba(217, 217, 217, 0) 90.2%)`,
  boxShadow: `0px 6.4px 32px 0px rgba(0, 0, 0, 0.1)`,
  transform: 'rotate(-5.33deg)',
  borderRadius: '48px',
  width: '349.15px',
  height: '252.77px',
  zIndex: -1,
}))

export const Footer = styled(Box)(({ theme }) => ({
  color: theme.palette.primary.main,
  padding: theme.spacing(3),
  zIndex: 1,
  display: 'flex',
  flexDirection: 'column',

  [`& .${typographyClasses.root}`]: {
    fontSize: '14px',
  },

  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(0, 3),
  },
}))

export const FooterRow = styled(Stack)(({ theme }) => ({
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: theme.spacing(2),
  margin: theme.spacing(0, 0, 1),

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    gap: theme.spacing(4),
  },
}))

export const FooterAside = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
  alignItems: 'center',

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    gap: theme.spacing(4),
  },
}))

export const FooterLinks = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
  alignItems: 'center',
  justifySelf: 'flex-end',

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    marginLeft: 'auto',
    marginTop: '1px',
  },
}))

export const FooterIconLink = styled(CIQIconButton)<CIQIconButtonProps & Pick<LinkProps, 'href' | 'target' | 'rel'>>(
  () => ({})
)

export const HeaderToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: '0 !important',
  display: 'flex',
  padding: '3px !important',
  width: '100%',
  gap: '5px',
}))

export const HeaderLink = styled(
  ({
    component = Link,
    ...props
  }: Omit<ButtonProps, 'variant'> & Pick<Required<LinkProps>, 'href'> & Pick<LinkProps, 'target' | 'rel'>) => (
    <Button component={component} variant="contained" {...props} />
  )
)(({ theme, component = Link, href, target, color = 'primary' }) => {
  const pathname = usePathname()
  const active = component === Link && !!pathname && (href === pathname || pathname.startsWith(`${href}/`))

  return {
    [`&.${buttonClasses.root}`]: {
      backgroundColor: active ? theme.palette[color].main : 'transparent',
      color: active || color === 'info' ? theme.palette[color].contrastText : theme.palette[color].main,

      '&:hover': {
        backgroundColor: theme.palette[color].main,
        color: theme.palette[color].contrastText,
      },

      '&:focus': {
        backgroundColor: theme.palette[color].main,
        color: theme.palette[color].contrastText,
      },

      ...(active && {
        background: theme.palette[color].main,
      }),
    },
  }
})
